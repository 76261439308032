import {
  Badge,
  Box,
  Button,
  Drawer,
  IconButton,
  Typography
} from '@material-ui/core';
import { ChatBubble } from '@material-ui/icons';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { XCircle } from 'react-feather';
import New from './new';
import Lists from './list';
import AddQrCodeToPdf from './qrtopdf';
import { SocketContext } from 'src/context/SocketContext';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Messages from './messages';
import axios from 'axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

const useStyles = makeStyles({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto'
  }
});

const Inquiries = () => {
  const classes = useStyles();
  const [category, setCategory] = useState('lists'); // lists new messages
  const { user } = useSelector(state => state.account);
  const [drawer, setDrawer] = useState({ open: false });
  const { socket } = React.useContext(SocketContext);
  const { enqueueSnackbar } = useSnackbar();
  const [rerender, setRerender] = useState(1);
  const [selectedInquiry, setSelectedInquiry] = useState(null);
  const rolename = user?.roles[0]?.name.trim();

  const currentId = isUser ? user?.id : user?.roles?.[0]?.id;
  const isUser = rolename === 'user';
  const mounted = useIsMountedRef();
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getInquiries = React.useCallback(() => {
    axios
      .get(`/api/account/inquiries`, {
        params: {
          type: isUser ? 'user' : 'office',
          user_id: user?.id,
          role_id: user?.roles[0]?.id,
          rerender
        }
      })
      .then(response => {
        if (mounted.current) {
          if (response.data.data.length > 0) {
            const updatedData = response.data.data.map(data => {
              let msgs = data.inquiries_msgs ?? [];
              let lastMsg =
                msgs.length > 0 ? msgs[msgs.length - 1].message : '';
              let unreadMsgs = msgs.filter(
                x =>
                  parseInt(x.is_view) === 0 &&
                  parseInt(x.receiver_id) === parseInt(currentId)
              );

              const hasUnread = unreadMsgs.length > 0;
              return {
                ...data,
                last_message: lastMsg,
                hasunread: hasUnread
              };
            });

            setList(updatedData);
          }
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false));
  }, [mounted, user, isUser, rerender]);

  React.useEffect(() => {
    getInquiries();
  }, [getInquiries]);

  React.useEffect(() => {
    if (user?.id) {
      socket.emit('join', {
        userId: user.id,
        roleId: user?.roles?.[0].id
      });

      // received inquries
      socket.on('sent-inquiry', data => {
        enqueueSnackbar('Inquiry successfully send.', {
          variant: 'success'
        });

        setRerender(rerender + 1);
      });
    }
  }, [user]);

  const hasUnreadMessage = list.filter(x => x.hasunread)?.length ?? 0;

  return (
    <Box
      sx={{
        right: '30px',
        position: 'absolute',
        bottom: '30px',
        zIndex: 1000,
        borderRadius: '50%'
      }}
    >
      <Badge badgeContent={hasUnreadMessage} color="error">
        <Button
          variant="contained"
          color="primary"
          startIcon={<ChatBubble />}
          onClick={() =>
            setDrawer({
              open: true
            })
          }
        >
          Inquiry
        </Button>
      </Badge>

      <Drawer
        anchor={'right'}
        open={drawer.open}
        onClose={() =>
          setDrawer({
            open: false
          })
        }
      >
        <Box
          sx={{
            width: { xs: '100vw', md: '500px' },
            padding: '10px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box>
              <Typography variant="h4"> Inquries </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px'
              }}
            >
              <Box>
                {rolename === 'user' ? (
                  <Button
                    variant={
                      category === 'new' || category === 'messages'
                        ? 'outlined'
                        : 'contained'
                    }
                    color="primary"
                    onClick={() => {
                      if (category === 'lists') {
                        setCategory('new');
                        return;
                      }

                      setCategory('lists');
                    }}
                  >
                    {category === 'new' || category === 'messages'
                      ? 'Back to list'
                      : 'New Inqury'}
                  </Button>
                ) : (
                  <Button
                    variant={'outlined'}
                    color="primary"
                    onClick={() => {
                      setCategory('lists');
                    }}
                    style={{
                      display: category === 'lists' ? 'none' : 'block'
                    }}
                  >
                    {' '}
                    Back to list
                  </Button>
                )}
              </Box>
              <Box>
                {' '}
                <IconButton
                  onClick={() =>
                    setDrawer({
                      open: false
                    })
                  }
                  color="primary"
                >
                  <XCircle />
                </IconButton>{' '}
              </Box>
            </Box>
          </Box>

          <Box>
            {category === 'new' && (
              <New onRerender={() => setRerender(rerender + 1)} />
            )}

            {category === 'lists' && (
              <Lists
                list={list}
                isLoading={isLoading}
                rerender={rerender}
                rolename={rolename}
                setCategory={setCategory}
                setSelectedInquiry={setSelectedInquiry}
                currentId={currentId}
              />
            )}

            {category === 'messages' && selectedInquiry && (
              <Messages
                inquiry={selectedInquiry}
                rolename={rolename}
                rerender={rerender}
              />
            )}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Inquiries;
