import {
  Box,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Typography
} from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingScreen from 'src/components/LoadingScreen';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

const Lists = ({
  currentId,
  list,
  isLoading,
  rerender,
  rolename,
  setSelectedInquiry,
  setCategory
}) => {
  return (
    <Box>
      {isLoading ? (
        <LoadingScreen />
      ) : list.length === 0 ? (
        <Box>
          <Typography> No record found.</Typography>
        </Box>
      ) : (
        <Box>
          <Box mb={3}>
            <Typography> List of inquries </Typography>
          </Box>

          <List>
            {list.map((data, key) => {
              return (
                <ListItem
                  button
                  key={key}
                  onClick={() => {
                    setSelectedInquiry(data);
                    setCategory('messages');
                  }}
                >
                  {data.hasunread && (
                    <Box sx={{ mr: 1 }}>
                      <FiberManualRecord
                        style={{ fill: 'red', fontSize: '10px' }}
                      />
                    </Box>
                  )}

                  <ListItemText
                    primary={data.subject}
                    secondary={data.last_message}
                  />
                </ListItem>
              );
            })}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default Lists;
