import { Box, Button, TextField, Typography } from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import LoadingScreen from 'src/components/LoadingScreen';
import { SocketContext } from 'src/context/SocketContext';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

const Messages = ({ inquiry, rolename }) => {
  const isUser = rolename === 'user';
  const { user } = useSelector(state => state.account);
  const mounted = useIsMountedRef();
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { socket } = React.useContext(SocketContext);
  const messagesEndRef = useRef(null);
  const currentId = isUser ? user?.id : user?.roles[0]?.id;
  const [renderMSG, setRenderMSG] = useState(1);
  const getInquiryMessages = React.useCallback(() => {
    axios
      .get(`/api/account/inquiries/messages`, {
        params: {
          type: isUser ? 'user' : 'office',
          user_id: user?.id,
          role_id: user?.roles[0]?.id,
          inquiry_id: inquiry?.id
        }
      })
      .then(response => {
        if (mounted.current) {
          setList(response.data.data);
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false));
  }, [mounted, user, isUser, inquiry]);

  const handleSendMsg = () => {
    if (message?.trim() === '' || message.length === 0) {
      enqueueSnackbar('Message is a required field', { variant: 'success ' });
      return;
    }

    let values = {
      type: isUser ? 'user' : 'office',
      sender_id: isUser ? inquiry.user_id : inquiry.sent_to_role_id,
      receiver_id: isUser ? inquiry.sent_to_role_id : inquiry.user_id,
      message,
      inquiry_id: inquiry?.id,
      datetime: moment().format('YYYY-MM-DD HH:mm')
    };

    socket.emit('sent-inquiry-message', values);
    setList(prev => prev.concat(values));
    setMessage('');
    setRenderMSG(renderMSG + 1);
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 1000);
  }, [renderMSG]);

  React.useEffect(() => {
    getInquiryMessages();

    socket.emit('set-inquiry-message-asview', {
      inquiry_id: inquiry?.id,
      type: isUser ? 'user' : 'office',
      receiver_id: isUser ? inquiry.user_id : inquiry.sent_to_role_id
    });

    socket.on('received-inquiry-message', data => {
      enqueueSnackbar('New message found.', {
        variant: 'success'
      });

      setList(prev => prev.concat(data));
      setRenderMSG(renderMSG + 1);
    });
  }, [getInquiryMessages]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Box>
      <Box sx={{ minHeight: 'calc(100vh - 150px)' }}>
        {list.length === 0 ? (
          <Typography> No message found. </Typography>
        ) : (
          list.map((data, key) => {
            return (
              <Box key={key} sx={{ my: 3 }}>
                {data.sender_id === currentId ? (
                  <Box
                    sx={{
                      maxWidth: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: '350px',
                        padding: '10px',
                        borderRadius: '12px',
                        backgroundColor: '#0099ff'
                      }}
                    >
                      <Typography variant="body2"> {data.message}</Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      maxWidth: '100%',
                      display: 'flex',
                      justifyContent: 'flex-start'
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: '350px',
                        padding: '10px',
                        borderRadius: '12px',
                        backgroundColor: '#f0f0f0'
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          color: '#1C1E21'
                        }}
                      >
                        {' '}
                        {data.message}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            );
          })
        )}
        <div ref={messagesEndRef} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px'
        }}
      >
        <TextField
          label="Enter message"
          value={message}
          onChange={e => setMessage(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <Button variant="contained" color="primary" onClick={handleSendMsg}>
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default Messages;
