/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/pages/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/Guard/AuthGuard';
import GuestGuard from 'src/components/Guard/GuestGuard';
import AdminGuard from './components/Guard/AdminGuard';

import FireGuard from './components/Guard/FireGuard';
import AssessorGuard from './components/Guard/AssessorGuard';
import EngineeringGuard from './components/Guard/EngineeringGuard';
import MenroGuard from './components/Guard/MenroGuard';
import MaoGuard from './components/Guard/MaoGuard';
import MpdoGuard from './components/Guard/Mpdoguard';
import RhuGuard from './components/Guard/RhuGuard';
import PesoGuard from './components/Guard/PeroGuard';
import BarangayGuard from './components/Guard/BarangayGuard';
import BploGuard from './components/Guard/BploGuard';
import AffiliateGuard from './components/Guard/AffiliateGuard';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/home" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login/verification',
    component: lazy(() => import('src/views/auth/LoginView/VerifyCode'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/recover/password',
    component: lazy(() => import('src/views/auth/RecoverPasswordView'))
  },

  {
    exact: true,
    guard: GuestGuard,
    path: '/recover/password/reset-form/:email/:token',
    component: lazy(() =>
      import('src/views/auth/RecoverPasswordView/NewPasswordView/')
    )
  },

  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },

  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard-alternative',
        component: lazy(() =>
          import('src/views/reports/DashboardAlternativeView')
        )
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() => import('src/views/management/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId',
        component: lazy(() =>
          import('src/views/management/CustomerDetailsView')
        )
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId/edit',
        component: lazy(() => import('src/views/management/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/management/products',
        component: lazy(() => import('src/views/management/ProductListView'))
      },
      {
        exact: true,
        path: '/app/management/products/create',
        component: lazy(() => import('src/views/management/ProductCreateView'))
      },
      {
        exact: true,
        path: '/app/management/orders',
        component: lazy(() => import('src/views/management/OrderListView'))
      },
      {
        exact: true,
        path: '/app/management/orders/:orderId',
        component: lazy(() => import('src/views/management/OrderDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/invoices',
        component: lazy(() => import('src/views/management/InvoiceListView'))
      },
      {
        exact: true,
        path: '/app/management/invoices/:invoiceId',
        component: lazy(() => import('src/views/management/InvoiceDetailsView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />
      },
      {
        exact: true,
        path: '/app/management/companies',
        component: lazy(() => import('src/views/management/Companies'))
      },
      {
        exact: true,
        path: '/app/management/companies/create',
        component: lazy(() => import('src/views/management/Companies/Create'))
      },
      {
        exact: true,
        path: '/app/management/companies/payment/history',
        component: lazy(() => import('src/views/reports/DashboardView/history'))
      },
      {
        exact: true,
        path: '/app/management/companies/account/soa',
        component: lazy(() => import('src/views/reports/DashboardView/soa'))
      },
      {
        exact: true,
        path: '/app/management/companies/sentattachment/:type/:brgycode',
        component: lazy(() =>
          import('src/views/management/Companies/RequestRegistration')
        )
      },
      {
        exact: true,
        path: '/app/management/companies/edit/:company_code',
        component: lazy(() => import('src/views/management/Companies/Edit'))
      },

      {
        exact: true,
        path: '/app/request/holdings/files/:company_code',
        component: lazy(() => import('src/views/reports/OfficesUploadFile'))
      },

      // {
      //   exact: true,
      //   path: '/app/management/companies/assessment/details/currentdue/payment/notifications',
      //   component: lazy(() => import('src/views/management/Companies/Assessment/Details/SampleNotification'))
      // },
      {
        exact: true,
        path: '/app/management/companies/holdings/location/:type/:company_code',
        component: lazy(() => import('src/views/pages/location'))
      },

      {
        exact: true,
        path: '/app/management/companies/assessment/:company_code',
        component: lazy(() =>
          import('src/views/management/Companies/Assessment')
        )
      },
      {
        exact: true,
        path:
          '/app/management/companies/assessment/request/requirements/sent/:assessment_id/:brgycode/:company_name',
        component: lazy(() =>
          import('src/views/management/Companies/Assessment/Requirements')
        )
      },
      {
        exact: true,
        path: '/app/management/companies/assessment/request/:company_code',
        component: lazy(() =>
          import('src/views/management/Companies/Assessment/Request')
        )
      },
      {
        exact: true,
        path:
          '/app/management/companies/assessment/details/:assessment_code/:company_code',
        component: lazy(() =>
          import('src/views/management/Companies/Assessment/Details')
        )
      },
      {
        exact: true,
        path:
          '/app/management/companies/assessment/details/paymentsuccessful/:assessment_code/:company_code',
        component: lazy(() =>
          import(
            'src/views/management/Companies/Assessment/Details/PaynamicPaymentSuccessful'
          )
        )
      },
      {
        exact: true,
        path:
          '/app/management/companies/assessment/details/currentdue/payments/gcash/redirect/failed',
        component: lazy(() =>
          import(
            'src/views/management/Companies/Assessment/Details/PaymentDialogGcashRedirectFailed'
          )
        )
      },
      {
        exact: true,
        path:
          '/app/management/companies/assessment/edit/:assessment_code/:company_code',
        component: lazy(() =>
          import('src/views/management/Companies/Assessment/Edit')
        )
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: '/app/kanban',
        component: lazy(() => import('src/views/kanban/KanbanView'))
      },
      {
        exact: true,
        path: ['/app/chat/new', '/app/chat/:threadKey'],
        component: lazy(() => import('src/views/chat/ChatView'))
      },
      {
        exact: true,
        path: '/app/chat',
        component: () => <Redirect to="/app/chat/new" />
      },
      {
        exact: true,
        path: [
          '/app/mail/label/:customLabel/:mailId?',
          '/app/mail/:systemLabel/:mailId?'
        ],
        component: lazy(() => import('src/views/mail/MailView'))
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to="/app/mail/all" />
      },
      {
        exact: true,
        path: '/app/projects/overview',
        component: lazy(() => import('src/views/projects/OverviewView'))
      },
      {
        exact: true,
        path: '/app/projects/browse',
        component: lazy(() => import('src/views/projects/ProjectBrowseView'))
      },
      {
        exact: true,
        path: '/app/projects/create',
        component: lazy(() => import('src/views/projects/ProjectCreateView'))
      },
      {
        exact: true,
        path: '/app/projects/:id',
        component: lazy(() => import('src/views/projects/ProjectDetailsView'))
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to="/app/projects/browse" />
      },
      {
        exact: true,
        path: '/app/social/feed',
        component: lazy(() => import('src/views/social/FeedView'))
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/social/profile" />
      },
      {
        exact: true,
        path: '/app/extra/charts/apex',
        component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/formik',
        component: lazy(() => import('src/views/extra/forms/FormikView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/redux',
        component: lazy(() => import('src/views/extra/forms/ReduxFormView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/draft-js',
        component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/quill',
        component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/administrator',
    layout: DashboardLayout,
    guard: AdminGuard,
    routes: [
      {
        exact: true,
        path: '/administrator',
        component: () => <Redirect to="/administrator/dashboard" />
      },
      {
        exact: true,
        path: '/administrator/dashboard',
        component: lazy(() => import('src/views/reports/DashboardAdmin'))
      },
      {
        exact: true,
        path: '/administrator/email/sent',
        component: lazy(() => import('src/views/reports/DashboardAdmin/Email'))
      },
      {
        exact: true,
        path: '/administrator/request/clearances',
        component: lazy(() => import('src/views/reports/DashboardAdmin/Report'))
      },
      {
        exact: true,
        path: '/administrator/users',
        component: lazy(() => import('src/views/reports/DashboardAdmin/Users'))
      },
      {
        exact: true,
        path: '/administrator/requirements',
        component: lazy(() =>
          import('src/views/reports/DashboardAdmin/Requirements')
        )
      },
      {
        exact: true,
        path: '/administrator/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/administrator/report/paynamics/payment',
        component: lazy(() =>
          import('src/views/reports/DashboardAdmin/Report/paynamics')
        )
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  },

  // fire routes

  {
    path: '/fire',
    layout: DashboardLayout,
    guard: FireGuard,
    routes: [
      {
        exact: true,
        path: '/fire',
        component: () => <Redirect to="/fire/dashboard" />
      },
      {
        exact: true,
        path: '/fire/dashboard',
        component: lazy(() => import('src/views/reports/DashboardFire'))
      },
      {
        exact: true,
        path: '/fire/request/clearances',
        component: lazy(() => import('src/views/reports/DashboardFire/Report'))
      },
      {
        exact: true,
        path: '/fire/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/fire/request/holdings/location/:type/:company_code',
        component: lazy(() => import('src/views/pages/location'))
      },
      {
        exact: true,
        path: '/fire/request/holdings/list',
        component: lazy(() => import('src/views/pages/holdings'))
      },
      ,
      {
        exact: true,
        path: '/fire/request/holdings/files/:company_code/:assessment_code',
        component: lazy(() => import('src/views/reports/OfficesUploadFile'))
      }
    ]
  },

  // affiliate
  {
    path: '/affiliate',
    layout: DashboardLayout,
    guard: AffiliateGuard,
    routes: [
      {
        exact: true,
        path: '/affiliate',
        component: () => <Redirect to="/affiliate/dashboard" />
      },
      {
        exact: true,
        path: '/affiliate/dashboard',
        component: lazy(() => import('src/views/reports/DashboardAffeliate'))
      },

      {
        exact: true,
        path: '/affiliate/scanner/result/:qrcode',
        component: lazy(() =>
          import('src/views/reports/DashboardAffeliate/scanner/Result')
        )
      }
    ]
  },

  // assessor routes
  {
    path: '/assessor',
    layout: DashboardLayout,
    guard: AssessorGuard,
    routes: [
      {
        exact: true,
        path: '/assessor',
        component: () => <Redirect to="/assessor/dashboard" />
      },
      {
        exact: true,
        path: '/assessor/dashboard',
        component: lazy(() => import('src/views/reports/DashboardAssessor'))
      },
      {
        exact: true,
        path: '/assessor/request/clearances',
        component: lazy(() =>
          import('src/views/reports/DashboardAssessor/Report')
        )
      },
      {
        exact: true,
        path: '/assessor/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/assessor/request/holdings/location/:type/:company_code',
        component: lazy(() => import('src/views/pages/location'))
      },
      {
        exact: true,
        path: '/assessor/request/holdings/list',
        component: lazy(() => import('src/views/pages/holdings'))
      },
      {
        exact: true,
        path: '/assessor/request/holdings/files/:company_code/:assessment_code',
        component: lazy(() => import('src/views/reports/OfficesUploadFile'))
      }
    ]
  },

  // engineering routes
  {
    path: '/engineering',
    layout: DashboardLayout,
    guard: EngineeringGuard,
    routes: [
      {
        exact: true,
        path: '/engineering',
        component: () => <Redirect to="/engineering/dashboard" />
      },
      {
        exact: true,
        path: '/engineering/dashboard',
        component: lazy(() => import('src/views/reports/DashboardEngineer'))
      },
      {
        exact: true,
        path: '/engineering/request/clearances',
        component: lazy(() =>
          import('src/views/reports/DashboardEngineer/Report')
        )
      },
      {
        exact: true,
        path: '/engineering/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/engineering/request/holdings/location/:type/:company_code',
        component: lazy(() => import('src/views/pages/location'))
      },
      {
        exact: true,
        path: '/engineering/request/holdings/list',
        component: lazy(() => import('src/views/pages/holdings'))
      }
    ]
  },

  // menro routes
  {
    path: '/menro',
    layout: DashboardLayout,
    guard: MenroGuard,
    routes: [
      {
        exact: true,
        path: '/menro',
        component: () => <Redirect to="/menro/dashboard" />
      },
      {
        exact: true,
        path: '/menro/dashboard',
        component: lazy(() => import('src/views/reports/DashboardMenro'))
      },
      {
        exact: true,
        path: '/menro/request/clearances',
        component: lazy(() => import('src/views/reports/DashboardMenro/Report'))
      },
      {
        exact: true,
        path: '/menro/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/menro/request/holdings/location/:type/:company_code',
        component: lazy(() => import('src/views/pages/location'))
      },
      {
        exact: true,
        path: '/menro/request/holdings/list',
        component: lazy(() => import('src/views/pages/holdings'))
      },
      {
        exact: true,
        path: '/menro/request/holdings/files/:company_code/:assessment_code',
        component: lazy(() => import('src/views/reports/OfficesUploadFile'))
      }
    ]
  },

  // mao routes
  {
    path: '/mao',
    layout: DashboardLayout,
    guard: MaoGuard,
    routes: [
      {
        exact: true,
        path: '/mao',
        component: () => <Redirect to="/mao/dashboard" />
      },
      {
        exact: true,
        path: '/mao/dashboard',
        component: lazy(() => import('src/views/reports/DashboardMao'))
      },
      {
        exact: true,
        path: '/mao/request/clearances',
        component: lazy(() => import('src/views/reports/DashboardMao/Report'))
      },
      {
        exact: true,
        path: '/mao/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/mao/request/holdings/location/:type/:company_code',
        component: lazy(() => import('src/views/pages/location'))
      },
      {
        exact: true,
        path: '/mao/request/holdings/list',
        component: lazy(() => import('src/views/pages/holdings'))
      },
      {
        exact: true,
        path: '/mao/request/holdings/files/:company_code/:assessment_code',
        component: lazy(() => import('src/views/reports/OfficesUploadFile'))
      }
    ]
  },

  // mpdo routes
  {
    path: '/mpdo',
    layout: DashboardLayout,
    guard: MpdoGuard,
    routes: [
      {
        exact: true,
        path: '/mpdo',
        component: () => <Redirect to="/mpdo/dashboard" />
      },
      {
        exact: true,
        path: '/mpdo/dashboard',
        component: lazy(() => import('src/views/reports/DashboardMpdo'))
      },
      {
        exact: true,
        path: '/mpdo/request/clearances',
        component: lazy(() => import('src/views/reports/DashboardMpdo/Report'))
      },
      {
        exact: true,
        path: '/mpdo/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/mpdo/request/holdings/location/:type/:company_code',
        component: lazy(() => import('src/views/pages/location'))
      },
      {
        exact: true,
        path: '/mpdo/request/holdings/list',
        component: lazy(() => import('src/views/pages/holdings'))
      },
      {
        exact: true,
        path: '/mpdo/request/holdings/files/:company_code/:assessment_code',
        component: lazy(() => import('src/views/reports/OfficesUploadFile'))
      }
    ]
  },

  // rhu routes
  {
    path: '/rhu',
    layout: DashboardLayout,
    guard: RhuGuard,
    routes: [
      {
        exact: true,
        path: '/rhu',
        component: () => <Redirect to="/rhu/dashboard" />
      },
      {
        exact: true,
        path: '/rhu/dashboard',
        component: lazy(() => import('src/views/reports/DashboardRhu'))
      },
      {
        exact: true,
        path: '/rhu/request/clearances',
        component: lazy(() => import('src/views/reports/DashboardRhu/Report'))
      },
      {
        exact: true,
        path: '/rhu/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/rhu/request/holdings/location/:type/:company_code',
        component: lazy(() => import('src/views/pages/location'))
      },
      {
        exact: true,
        path: '/rhu/request/holdings/list',
        component: lazy(() => import('src/views/pages/holdings'))
      },
      {
        exact: true,
        path: '/rhu/request/holdings/files/:company_code/:assessment_code',
        component: lazy(() => import('src/views/reports/OfficesUploadFile'))
      }
    ]
  },

  // peso routes
  {
    path: '/peso',
    layout: DashboardLayout,
    guard: PesoGuard,
    routes: [
      {
        exact: true,
        path: '/peso',
        component: () => <Redirect to="/peso/dashboard" />
      },
      {
        exact: true,
        path: '/peso/dashboard',
        component: lazy(() => import('src/views/reports/DashboardPeso'))
      },
      {
        exact: true,
        path: '/peso/request/clearances',
        component: lazy(() => import('src/views/reports/DashboardPeso/Report'))
      },
      {
        exact: true,
        path: '/peso/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/peso/request/holdings/location/:type/:company_code',
        component: lazy(() => import('src/views/pages/location'))
      },
      {
        exact: true,
        path: '/peso/request/holdings/list',
        component: lazy(() => import('src/views/pages/holdings'))
      },
      {
        exact: true,
        path: '/peso/request/holdings/files/:company_code/:assessment_code',
        component: lazy(() => import('src/views/reports/OfficesUploadFile'))
      }
    ]
  },

  // barangay routes
  {
    path: '/barangay',
    layout: DashboardLayout,
    guard: BarangayGuard,
    routes: [
      {
        exact: true,
        path: '/barangay',
        component: () => <Redirect to="/barangay/dashboard" />
      },
      {
        exact: true,
        path: '/barangay/dashboard',
        component: lazy(() => import('src/views/reports/DashboardBarangay'))
      },
      {
        exact: true,
        path: '/barangay/request/clearances',
        component: lazy(() =>
          import('src/views/reports/DashboardBarangay/Report')
        )
      },
      {
        exact: true,
        path: '/barangay/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      }
    ]
  },

  // bplo routes

  {
    path: '/bplo',
    layout: DashboardLayout,
    guard: BploGuard,
    routes: [
      {
        exact: true,
        path: '/bplo',
        component: () => <Redirect to="/bplo/dashboard" />
      },
      {
        exact: true,
        path: '/bplo/dashboard',
        component: lazy(() => import('src/views/reports/DashboardBplo'))
      },
      {
        exact: true,
        path: '/bplo/request/clearances',
        component: lazy(() => import('src/views/reports/DashboardBplo/Report'))
      },
      {
        exact: true,
        path: '/bplo/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/bplo/request/holdings/location/:type/:company_code',
        component: lazy(() => import('src/views/pages/location'))
      },
      {
        exact: true,
        path: '/bplo/request/holdings/list',
        component: lazy(() => import('src/views/pages/holdings'))
      },

      {
        exact: true,
        path: '/bplo/request/holdings/files/:company_code/:assessment_code',
        component: lazy(() => import('src/views/reports/OfficesUploadFile'))
      }
    ]
  },

  {
    path: '/docs',
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/docs',
        component: () => <Redirect to="/docs/welcome" />
      },
      {
        exact: true,
        path: '/docs/welcome',
        component: lazy(() => import('src/views/docs/WelcomeView'))
      },
      {
        exact: true,
        path: '/docs/getting-started',
        component: lazy(() => import('src/views/docs/GettingStartedView'))
      },
      {
        exact: true,
        path: '/docs/environment-variables',
        component: lazy(() => import('src/views/docs/EnvironmentVariablesView'))
      },
      {
        exact: true,
        path: '/docs/deployment',
        component: lazy(() => import('src/views/docs/DeploymentView'))
      },
      {
        exact: true,
        path: '/docs/api-calls',
        component: lazy(() => import('src/views/docs/ApiCallsView'))
      },
      {
        exact: true,
        path: '/docs/analytics',
        component: lazy(() => import('src/views/docs/AnalyticsView'))
      },
      {
        exact: true,
        path: '/docs/authentication',
        component: lazy(() => import('src/views/docs/AuthenticationView'))
      },
      {
        exact: true,
        path: '/docs/routing',
        component: lazy(() => import('src/views/docs/RoutingView'))
      },
      {
        exact: true,
        path: '/docs/settings',
        component: lazy(() => import('src/views/docs/SettingsView'))
      },
      {
        exact: true,
        path: '/docs/state-management',
        component: lazy(() => import('src/views/docs/StateManagementView'))
      },
      {
        exact: true,
        path: '/docs/theming',
        component: lazy(() => import('src/views/docs/ThemingView'))
      },
      {
        exact: true,
        path: '/docs/support',
        component: lazy(() => import('src/views/docs/SupportView'))
      },
      {
        exact: true,
        path: '/docs/changelog',
        component: lazy(() => import('src/views/docs/ChangelogView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: HomeView
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pages/PricingView'))
      },
      {
        exact: true,
        path: '/home/terms-and-conditions',
        component: lazy(() => import('src/views/pages/TermsAndConditions'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  //** ebps routes **//

  {
    path: '/ebpls',
    // layout: '',
    routes: [
      {
        exact: true,
        path: '/registration'
        // component: lazy(() => import('src/ebps/users/Registration'))
      },
      {
        exact: true,
        path: '/ebpls/login',
        // layout: MainLayout,
        component: lazy(() => import('src/views/auth/LoginView'))
      }
    ]
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
